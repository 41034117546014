import { FormConfig } from './FormConfig.entity';

export const loginFormConfig: FormConfig = {
  id: 'login',

  fields: [
    {
      id: 'email',
      type: 'text',
      name: 'username',
      placeholder: ({ strings }) => strings?.emailPlaceholder,
      validate: ({ value, strings }) => {
        if (!/^\S+@\S+\.\S+$/.test(value)) return strings.invalidEmail;
      },
    },
    {
      id: 'password',
      type: 'password',
      name: 'password',
      placeholder: ({ strings }) => strings?.passwordPlaceholder,
      validate: ({ value, strings }) => {
        if (value?.length < 8) return strings.passwordTooShort;
        if (!/[A-z]/.test(value)) return strings.passwordNoLetter;
        if (!/\d/.test(value)) return strings.passwordNoNumber;
      },
    },
  ],
} as const satisfies FormConfig;
