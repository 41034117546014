/* eslint-disable @typescript-eslint/naming-convention */

'use client';

import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { unwrap, atomWithRefresh } from 'jotai/utils';

import { authTokenAtom } from '../Atoms/AuthToken.atom';
import { DashboardDataResponse } from '../Entities/Account.entity';
import { camelizeObject } from '@core/Utils/Object/CamelizeObject';

export const asyncUserDashboardDataAtom = atomWithRefresh(async get => {
  const authToken = get(authTokenAtom);

  console.log('[authToken]', { authToken });

  if (!authToken) return null;

  try {
    const response = await fetchJson<DashboardDataResponse>(`/api/border-control/users/dashboard`, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { Authorization: `Bearer ${authToken}` },
    });

    console.log('[fetched user data from dashboard', { response });

    if (typeof response === 'object') return camelizeObject(response);

    return null;
  } catch (error) {
    console.error('[fetch user error]', error, error?.status, error?.message);

    return null;
  }
});

export const userDashboardDataAtom = unwrap(asyncUserDashboardDataAtom, prev => prev ?? undefined);
