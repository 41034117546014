'use client';

import { memo } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { Toast } from '@core/Components/Toast/Toast';
import Button from '@gds/Button/Button';
import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';

import { accModalAtomFamily, activeAccModalAtom } from '../../Atoms/AccModal.atom';
import { AccountFormFields } from '../AccountFormData/AccountFormFields';
import { forgotPasswordFormConfig } from '../AccountFormData/ForgotPasswordFormConfig';

import { AccountFormDefaultMessages } from './AccountForm.messages'; // Adjust the import path as needed
import { useAccountForm } from './Hooks/UseAccountForm';
import loginIcon from './login.svg';

import styles from './AccLoginForm.module.css';

type Props = {
  strings: AccountFormDefaultMessages;
};

export const AccForgotPasswordForm = memo(({ strings }: Props) => {
  const isModalOpen = useAtomValue(accModalAtomFamily('forgotPassword'));
  const setActiveAccModal = useSetAtom(activeAccModalAtom);

  const { formRef, handleSubmit, isLoading, generalFormError } = useAccountForm({
    url: '/api/border-control/auth/forgot-password',
    successModalId: 'forgotPasswordSuccess',
  });

  return (
    <div className={styles.overlay} data-visible={isModalOpen}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <Typography variant="h5">{strings.forgotPassword}</Typography>
          <button className={styles.closeButton} onClick={() => setActiveAccModal(null)}>
            <Close />
          </button>
        </div>

        <div className={styles.content}>
          <img src={loginIcon.src} alt={strings.userIconAlt} />
          <Typography variant="h5">{strings.passwordReset}</Typography>

          <Typography variant="body3">{strings.passwordResetInstructions}</Typography>

          <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
            <AccountFormFields
              config={forgotPasswordFormConfig}
              formEl={formRef}
              strings={strings}
            />
            <Button loading={isLoading} fullWidth>
              {strings.sendResetInstructions}
            </Button>
            <Toast variant="error">{generalFormError}</Toast>
          </form>

          <Typography
            variant="body1"
            onClick={() => setActiveAccModal('login')}
            className={styles.switch}
          >
            {strings.rememberPassword} <span>{strings.signIn}</span>
          </Typography>
        </div>
      </div>
    </div>
  );
});

AccForgotPasswordForm.displayName = 'AccForgotPasswordForm';
