'use client';

import { memo, useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { Toast } from '@core/Components/Toast/Toast';
import { toastManagerAtom } from '@core/Components/Toast/ToastManager';
import Button from '@gds/Button/Button';
import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';

import { accModalAtomFamily, activeAccModalAtom } from '../../Atoms/AccModal.atom';

import { authTokenAtom } from '../../Atoms/AuthToken.atom';
import { asyncUserDashboardDataAtom } from '../../Atoms/UserDashboardData.atom';
import { asyncUserProfileAtom } from '../../Atoms/UserProfile.atom';

import { AccountFormFields } from '../AccountFormData/AccountFormFields';

import { confirmDetailsFormConfig } from '../AccountFormData/ConfirmDetailsFormConfig';

import { AccountFormDefaultMessages } from './AccountForm.messages';
import { useAccountForm } from './Hooks/UseAccountForm';
import loginIcon from './login.svg';

import styles from './AccLoginForm.module.css';

type Props = {
  strings: AccountFormDefaultMessages;
};

export const AccConfirmDetailsForm = memo(({ strings }: Props) => {
  const authToken = useAtomValue(authTokenAtom);
  const isConfirmDetailsModalOpen = useAtomValue(accModalAtomFamily('confirmDetails'));
  const refreshUserProfile = useSetAtom(asyncUserProfileAtom);
  const refreshUserDashboardData = useSetAtom(asyncUserDashboardDataAtom);
  const userProfile = useAtomValue(asyncUserProfileAtom);

  const setActiveAccModal = useSetAtom(activeAccModalAtom);
  const addToast = useSetAtom(toastManagerAtom);

  const { formRef, handleSubmit, isLoading, generalFormError } = useAccountForm({
    authToken: authToken!,
    url: '/api/border-control/users/profile',
    method: 'PATCH',
    successModalId: null,
    onSuccess: () => {
      addToast({ children: strings.detailsUpdatedSuccess, variant: 'success' });
      refreshUserProfile();
      refreshUserDashboardData();
    },
  });

  useEffect(() => {
    if (!isConfirmDetailsModalOpen || !userProfile) return;

    const fieldNames = confirmDetailsFormConfig.fields.map(field => field.name);

    fieldNames.forEach(fieldName => {
      const fieldRef = formRef?.current?.elements.namedItem(fieldName) as HTMLInputElement;
      const fieldValue = userProfile[fieldName as keyof typeof userProfile] as string;
      fieldRef?.setAttribute('value', fieldValue ?? '');
    });
  }, [isConfirmDetailsModalOpen, userProfile]);

  return (
    <div className={styles.overlay} data-visible={isConfirmDetailsModalOpen}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <Typography variant="h5">{strings.myHeycarAccount}</Typography>
          <button className={styles.closeButton} onClick={() => setActiveAccModal(null)}>
            <Close />
          </button>
        </div>

        <div className={styles.content}>
          <img src={loginIcon.src} alt={strings.userIconAlt} />
          <Typography variant="body1">{strings.thanksForSigningUp}</Typography>
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {strings.getToKnowYouBetter}
          </Typography>

          <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
            <AccountFormFields
              strings={strings}
              config={confirmDetailsFormConfig}
              formEl={formRef}
            />

            <Button loading={isLoading} fullWidth>
              {strings.confirm}
            </Button>
            <Toast variant="error">{generalFormError}</Toast>
          </form>
        </div>
      </div>
    </div>
  );
});

AccConfirmDetailsForm.displayName = 'AccConfirmDetailsForm';
