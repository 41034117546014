import { FormConfig } from './FormConfig.entity';

export const confirmDetailsFormConfig: FormConfig = {
  id: 'confirmDetails',

  fields: [
    {
      id: 'firstName',
      type: 'text',
      name: 'firstName',
      placeholder: ({ strings }) => strings?.firstName,
      validate: ({ value, strings }) => {
        if (value?.length < 3) return strings.requiredField;
      },
    },
    {
      id: 'lastName',
      type: 'text',
      name: 'lastName',
      placeholder: ({ strings }) => strings?.lastName,
      validate: ({ value, strings }) => {
        if (value?.length < 3) return strings.requiredField;
      },
    },
    {
      id: 'phoneNumber',
      type: 'text',
      name: 'phoneNumber',
      placeholder: ({ strings }) => strings?.phoneNumber,
      validate: ({ value, strings }) => {
        if (!value) return '';
        if (!/^\+?\d{8,}$/.test(value)) return strings.requiredField;
      },
    },
    {
      id: 'postcode',
      type: 'text',
      name: 'postcode',
      placeholder: ({ strings }) => strings?.postcode,
      validate: ({ value, strings }) => {
        if (!/^\d{4}$/.test(value)) return strings.requiredField;
      },
    },
  ],
} as const satisfies FormConfig;
