'use client';

import { memo } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';

import { accModalAtomFamily, activeAccModalAtom } from '../../Atoms/AccModal.atom';

import { AccountFormDefaultMessages } from './AccountForm.messages'; // Adjust the import path as needed
import loginIcon from './login.svg';

import styles from './AccLoginForm.module.css';

type Props = {
  strings: AccountFormDefaultMessages;
};

export const AccForgotPasswordSuccessForm = memo(({ strings }: Props) => {
  const isModalOpen = useAtomValue(accModalAtomFamily('forgotPasswordSuccess'));
  const setActiveAccModal = useSetAtom(activeAccModalAtom);

  return (
    <div className={styles.overlay} data-visible={isModalOpen}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <Typography variant="h5">{strings.myHeycarAccount}</Typography>
          <button className={styles.closeButton} onClick={() => setActiveAccModal(null)}>
            <Close />
          </button>
        </div>

        <div className={styles.content}>
          <img src={loginIcon.src} alt={strings.emailIconAlt} />
          <Typography variant="h5">{strings.passwordReset}</Typography>
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {strings.passwordResetSentMessage}
          </Typography>

          <div className={styles.divider} />

          <div className={styles.buttons}>
            <Typography variant="body4">
              {strings.emailNotReceived}{' '}
              <strong onClick={() => setActiveAccModal('forgotPassword')}>
                <u>{strings.resendRecoveryLink}</u>
              </strong>
            </Typography>
            <Typography variant="body4">
              {strings.wrongEmail}{' '}
              <strong onClick={() => setActiveAccModal('login')}>
                <u>{strings.backToSignIn}</u>
              </strong>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
});

AccForgotPasswordSuccessForm.displayName = 'AccForgotPasswordSuccessForm';
