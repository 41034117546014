import { FormConfig } from './FormConfig.entity';

export const forgotPasswordFormConfig: FormConfig = {
  id: 'forgotPassword',

  fields: [
    {
      id: 'email',
      type: 'text',
      name: 'email',
      placeholder: ({ strings }) => strings?.emailPlaceholder,
      validate: ({ value, strings }) => {
        if (!value) return strings.requiredField;
        if (!/^\S+@\S+\.\S+$/.test(value)) return strings.invalidEmail;
      },
    },
  ],
} as const satisfies FormConfig;
