'use client';

import { memo } from 'react';

import { useAtom, useSetAtom } from 'jotai';

import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';

import { accModalAtomFamily } from '../../Atoms/AccModal.atom';

import { AccountFormDefaultMessages } from './AccountForm.messages';
import loginIcon from './login.svg';

import styles from './AccLoginForm.module.css';

type Props = {
  strings: AccountFormDefaultMessages;
};

export const AccEmailPendingForm = memo(({ strings }: Props) => {
  const [isEmailPendingModalOpen, toggleEmailPendingModal] = useAtom(
    accModalAtomFamily('emailPending'),
  );
  const toggleSignupModal = useSetAtom(accModalAtomFamily('signup'));

  return (
    <div className={styles.overlay} data-visible={isEmailPendingModalOpen}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <Typography variant="h5">{strings.myHeycarAccount}</Typography>
          <button className={styles.closeButton} onClick={() => toggleEmailPendingModal(false)}>
            <Close />
          </button>
        </div>

        <div className={styles.content}>
          <img src={loginIcon.src} alt={strings.emailIconAlt} />
          <Typography variant="h5">{strings.welcome}</Typography>
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {strings.emailVerificationMessage}
          </Typography>

          <div className={styles.divider} />

          <div className={styles.buttons}>
            <Typography variant="body4">
              {strings.emailNotReceived}{' '}
              <strong onClick={() => toggleSignupModal()}>
                <u>{strings.resendVerificationEmail}</u>
              </strong>
            </Typography>
            <Typography variant="body4">
              {strings.wrongEmail}{' '}
              <strong onClick={() => toggleSignupModal()}>
                <u>{strings.backToSignUp}</u>
              </strong>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
});

AccEmailPendingForm.displayName = 'AccEmailPendingForm';
