'use client';

import { memo } from 'react';

import { useAtom, useSetAtom } from 'jotai';
import Link from 'next/link';

import { Toast } from '@core/Components/Toast/Toast';
import Button from '@gds/Button/Button';
import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';

import { accModalAtomFamily } from '../../Atoms/AccModal.atom';
import { AccountFormFields } from '../AccountFormData/AccountFormFields';
import { signupFormConfig } from '../AccountFormData/SignupFormConfig';

import { AccountFormDefaultMessages } from './AccountForm.messages';
import { useAccountForm } from './Hooks/UseAccountForm';
import loginIcon from './login.svg';

import styles from './AccLoginForm.module.css';

type Props = {
  strings: AccountFormDefaultMessages;
};

export const AccSignupForm = memo(({ strings }: Props) => {
  const [isLoginModalOpen, toggleSignupModal] = useAtom(accModalAtomFamily('signup'));
  const toggleLoginModal = useSetAtom(accModalAtomFamily('login'));

  const { formRef, handleSubmit, generalFormError, isLoading } = useAccountForm({
    url: '/api/border-control/auth/signup',
    successModalId: 'emailPending',
  });

  return (
    <div className={styles.overlay} data-visible={isLoginModalOpen}>
      <div className={styles.container} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <Typography variant="h5">{strings.myHeycarAccount}</Typography>
          <button className={styles.closeButton} onClick={() => toggleSignupModal(false)}>
            <Close />
          </button>
        </div>

        <div className={styles.content}>
          <img src={loginIcon.src} alt={strings.userIconAlt} />
          <Typography variant="body1">{strings.signUp}</Typography>

          <form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
            <AccountFormFields strings={strings} config={signupFormConfig} formEl={formRef} />

            <Typography variant="body2" className={styles.tAndC}>
              {strings.termsAndConditions}{' '}
              <Link href="/uk/terms-conditions" target="_blank">
                {strings.termsOfUse}
              </Link>{' '}
              {strings.and} <span>{strings.privacyPolicy}</span>.
            </Typography>
            <Button loading={isLoading} fullWidth>
              {strings.signUpButton}
            </Button>
            <Toast variant="error">{generalFormError}</Toast>
          </form>

          <Typography variant="body1" onClick={() => toggleLoginModal()} className={styles.switch}>
            {strings.alreadyHaveAccount} <span>{strings.signIn}</span>
          </Typography>
        </div>
      </div>
    </div>
  );
});

AccSignupForm.displayName = 'AccSignupForm';
